const JsCookies = require('js-cookie');
import { googleAnalytics } from './googleAnalytics';
import * as SentryClient from './sentry';

export default (() => {

    const isCookiesEnabled = () => JsCookies.get('Cookie_Consent') && JsCookies.get('Cookie_Category_analytics') === 'true';

    document.addEventListener('DOMContentLoaded', function () {
        if (isCookiesEnabled()) {
            googleAnalytics.init();
            SentryClient.turnOnSentry();
        }
    });

    document.addEventListener('cookie-consent-form-submit-successful', function () {
        if (isCookiesEnabled()) {
            if (googleAnalytics.getApprovalStatus() !== 'agree') {
                googleAnalytics.agree();
            }
        } else {
            googleAnalytics.disagree();
        }
    });

})();
