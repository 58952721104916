(function ($) {
    const body = $("body");
    const countdownAlert = $(".countdown-alert-wrapper");

    if (countdownAlert.length) {
        const countdownAlertClose = countdownAlert.find(".countdown-alert-close");
        body.addClass("countdown-alert-opened");

        countdownAlertClose.on("click", function (e) {
            e.preventDefault();

            body.removeClass("countdown-alert-opened");
        });
    }
})(jQuery);
