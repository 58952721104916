export default (() => {
    const headerElement = document.querySelector("header");

    const interval = setInterval(() => {
        const isScrolled = document.documentElement.scrollTop > 0;
        if (isScrolled) {
            headerElement.classList.toggle("header--with-shadow", isScrolled);
        }
    }, 16);
})();
