import { googleAnalyticsJs } from '@d-gs/google-analytics-js';

const googleAnalyticsId = process.env.GOOGLE_ANALYTICS_ID;

export const googleAnalytics = googleAnalyticsJs(
    [
        googleAnalyticsId,
    ],
    {
        forceEnabled: false,  // (default: false)
        stateKey: 'enabledGa',  // localStorage key (default: `enabledGa`)
        scriptId: 'GoogleTagManagerScript'  // gtag script id (default: `GoogleTagManagerScript`)
    }
);

if (localStorage.getItem('isEnabledGa') !== null && localStorage.getItem('enabledGa') === null) {
    localStorage.setItem('enabledGa', localStorage.getItem('isEnabledGa'));
}
