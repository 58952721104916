enum DATA_ATTRIBUTE {
	ELEMENT_ID = "data-toggle-id",
	CLASSNAME = "data-toggle-class",
}

export default (() => {
	const toggleElements = document.querySelectorAll("[data-toggle-id]");

	toggleElements.length &&
		toggleElements.forEach((element) => {
			(element as HTMLAnchorElement).addEventListener("click", (event) => {
				if ((event.currentTarget as HTMLAnchorElement).href.endsWith('#')) {
					event.preventDefault();
				}
				const toggleElementId = (event.currentTarget as HTMLElement).getAttribute(DATA_ATTRIBUTE.ELEMENT_ID);
				const toggleClass = (event.currentTarget as HTMLElement).getAttribute(DATA_ATTRIBUTE.CLASSNAME);

				document.getElementById(toggleElementId).classList.toggle(toggleClass);
			});
		});

    return true;
})();
