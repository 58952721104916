import * as Sentry from "@sentry/browser";

export function turnOnSentry() {
    if (process.env.SENTRY_DSN) {
        Sentry.init({
            dsn: process.env.SENTRY_DSN,
            environment: process.env.SENTRY_ENVIRONMENT,

            //maxBreadcrumbs: 50,
            attachStacktrace: true,

            // Alternatively, use `process.env.npm_package_version` for a dynamic release version
            // if your build tool supports it.
            integrations: [Sentry.browserTracingIntegration()],

            tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE),
            sampleRate: parseFloat(process.env.SENTRY_SAMPLE_RATE),
        });
    }
}
