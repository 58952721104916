import { createFlashMessage, TYPE_DANGER, TYPE_SUCCESS } from './flash-message';

const REGISTER_BUTTON_SELECTOR = '[data-register-session-id]';
const UNREGISTER_BUTTON_SELECTOR = '[data-unregister-session-id]';
const UNAVAILABLE_BUTTON_SELECTOR = '[data-unavailable-session-id]';

type TicketResponse = {
    success: boolean;
    status: 'not_logged_in' | 'not_registered' | 'registered' | null;
    message: string;
    availableCount: number;
}

export async function initProgramSessionTicketRegistration(popupContent: HTMLElement) {
    const registerButtons = popupContent.querySelectorAll(REGISTER_BUTTON_SELECTOR);
    const unregisterButtons = popupContent.querySelectorAll(UNREGISTER_BUTTON_SELECTOR);
    const unavailableButtons = popupContent.querySelectorAll(UNAVAILABLE_BUTTON_SELECTOR);

    function toggleRegistration(button, isRegistered, programSessionId) {

        function setLoading(button: HTMLElement, isLoading: boolean) {
            const spinner: HTMLElement = button.querySelector('.spinner');

            if (isLoading) {
                button.classList.add('disabled');
                spinner?.classList.remove('d-none');
            } else {
                button.classList.remove('disabled');
                spinner?.classList.add('d-none');
            }
        }

        function showRegisterButtons(show: boolean) {
            registerButtons.forEach((button: HTMLElement) => {
                if (show) {
                    button.classList.remove('d-none');
                } else {
                    button.classList.add('d-none');
                }
            });
        }

        function showUnregisterButtons(show: boolean) {
            unregisterButtons.forEach((button: HTMLElement) => {
                if (show) {
                    button.classList.remove('d-none');
                } else {
                    button.classList.add('d-none');
                }
            });
        }

        function showUnavailableButtons(show: boolean) {
            unavailableButtons.forEach((button: HTMLElement) => {
                if (show) {
                    button.classList.remove('d-none');
                } else {
                    button.classList.add('d-none');
                }
            });
        }

        button.addEventListener('click', async (e) => {
            e.preventDefault();

            if (button.classList.contains('disabled')) {
                return;
            }
            const apiUri = button.getAttribute('href');

            setLoading(button, true);

            const response = await fetch(apiUri, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            });

            if (response.ok) {
                const data: TicketResponse = await response.json();
                const programSessionEvent: HTMLElement = document.querySelector(`.program2-table__column__event[data-program-session-id="${programSessionId}"]`);

                if (data.success === true) {
                    if (isRegistered) {
                        showRegisterButtons(false);
                        showUnregisterButtons(true);
                    } else {
                        showRegisterButtons(true);
                        showUnregisterButtons(false);
                    }
                    showUnavailableButtons(false);

                    createFlashMessage(data.message, TYPE_SUCCESS);

                    if (programSessionEvent !== null) {
                        if (isRegistered) {
                            programSessionEvent.classList.add('program2-table__column__event--registered');
                        } else {
                            programSessionEvent.classList.remove('program2-table__column__event--registered');
                        }

                        programSessionEvent.dataset.available = data.availableCount === 0 ? 'false' : 'true';
                    }
                } else {
                    if (data.availableCount === 0) {
                        showRegisterButtons(false);
                        showUnregisterButtons(false);
                        showUnavailableButtons(true);
                    }
                    createFlashMessage(data.message, TYPE_DANGER);
                }
            }

            setLoading(button, false);
        });
    }

    registerButtons.forEach((button: HTMLElement) => {
        toggleRegistration(button, true, button.dataset.registerSessionId);
    });

    unregisterButtons.forEach((button: HTMLElement) => {
        toggleRegistration(button, false, button.dataset.unregisterSessionId);
    });
}

export async function refreshAvailability(popupContent: HTMLElement, availabilityUri: string) {
    const registerButtons = popupContent.querySelectorAll(REGISTER_BUTTON_SELECTOR);
    const unregisterButtons = popupContent.querySelectorAll(UNREGISTER_BUTTON_SELECTOR);
    const unavailableButtons = popupContent.querySelectorAll(UNAVAILABLE_BUTTON_SELECTOR);

    if (availabilityUri === null) {
        return;
    }

    function showRegistrationButtons() {
        unregisterButtons.forEach((button) => {
            button.classList.add('d-none');
        });
        unavailableButtons.forEach((button) => {
            button.classList.add('d-none');
        });
        registerButtons.forEach((button) => {
            button.classList.remove('d-none');
        });
    }

    function showUnregistrationButtons() {
        registerButtons.forEach((button) => {
            button.classList.add('d-none');
        });
        unavailableButtons.forEach((button) => {
            button.classList.add('d-none');
        });
        unregisterButtons.forEach((button) => {
            button.classList.remove('d-none');
        });
    }

    function showUnavailableButtons() {
        registerButtons.forEach((button) => {
            button.classList.add('d-none');
        });
        unregisterButtons.forEach((button) => {
            button.classList.add('d-none');
        });
        unavailableButtons.forEach((button) => {
            button.classList.remove('d-none');
        });
    }

    const response = await fetch(availabilityUri, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        },
    });

    if (response.ok) {
        const data: TicketResponse = await response.json();
        const isLogged = data.status !== 'not_logged_in';
        const isRegistered = data.status === 'registered';
        const availableCount = data.availableCount;

        if (isLogged) {
            if (isRegistered) {
                showUnregistrationButtons();
            } else {
                if (availableCount > 0) {
                    showRegistrationButtons();
                } else {
                    showUnavailableButtons();
                }
            }
        } else {
            showUnavailableButtons();
        }
    } else {
        console.error('Failed to refresh availability', response);
    }

    if (popupContent.querySelector('.js-registration-buttons') !== null) {
        popupContent.querySelector('.js-registration-buttons').classList.remove('d-none');
    }
}

