import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';

export const TYPE_SUCCESS = 'success';
export const TYPE_DANGER = 'danger';
export const TYPE_WARNING = 'warning';
export const TYPE_INFO = 'info';

const CLOSE_ALERT_AFTER_SECONDS = 10;

const alertIcons = {
    success: 'check-circle-fill',
    danger: 'exclamation-triangle-fill',
    warning: 'exclamation-triangle-fill',
    info: 'info-fill',
}

document.addEventListener('DOMContentLoaded', () => {
    const flashMessages = document.querySelectorAll('.messages > .alert');
    flashMessages.forEach((flashMessage) => {
        setTimeout(() => {
            const alert = bootstrap.Alert.getOrCreateInstance(flashMessage);
            if (alert) {
                alert.close();
            }
        }, CLOSE_ALERT_AFTER_SECONDS * 1000);
    });
});

function createFlashMessage(message: string, type: 'success' | 'danger' | 'warning' | 'info' = TYPE_INFO) {
    const flashMessagesContainer = document.getElementsByClassName('messages').item(0);
    if (!flashMessagesContainer) return;

    const alertDiv = document.createElement('div');
    alertDiv.classList.add('alert', `alert-${type}`, `border-${type}`, 'alert-dismissible', 'd-flex', 'align-items-center', 'fade', 'show', 'messages__message', 'w-100');
    alertDiv.role = 'alert';
    alertDiv.innerHTML = `
        <svg class="bi flex-shrink-0 me-4" role="img" aria-label="${type}:">
            <use xlink:href="#${alertIcons[type]}" />
        </svg>
        <div>${message}</div>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    `;

    flashMessagesContainer.appendChild(alertDiv);

    setTimeout(() => {
        const alert = bootstrap.Alert.getOrCreateInstance(alertDiv);

        if (alert) {
            alert.close();
        }
    }, CLOSE_ALERT_AFTER_SECONDS * 1000);
}

export { createFlashMessage };
