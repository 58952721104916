export default (() => {
    const timelines = document.querySelectorAll(".program2-table__column__item__timeslot");

    const updateTimelineWidth = () => {
        const programTable = document.querySelector("#program2-table");

        if (typeof programTable === 'undefined' || programTable === null) {
            return false;
        }

        timelines.length &&
        timelines.forEach((timeline: HTMLElement) => {
            timeline.style.width = `${programTable.clientWidth}px`;
        });
    };

    updateTimelineWidth();

    window.addEventListener('resize', updateTimelineWidth);

    const programEventElements = document.querySelectorAll(".program2-table__column__event");
    programEventElements.forEach((programEventElement: HTMLElement) => {
        if (programEventElement.clientHeight < 100) {
            programEventElement.querySelectorAll(".event__room").forEach((roomElement: HTMLElement) => {
                roomElement.style.display = "none";
            });
        }
    });

    return true;
})();
