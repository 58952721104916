export default (() => {
    const formElements = document.querySelectorAll('form');

    formElements.length && formElements.forEach((formElement: HTMLFormElement) => {
        formElement.addEventListener('submit', (event) => {
            const submitterElement = event.submitter;

            if (submitterElement instanceof HTMLButtonElement) {
                submitterElement.disabled = true;

                setTimeout(() => {
                    submitterElement.disabled = false;
                }, 5000);
            }
        });
    });

    return true;
})();
