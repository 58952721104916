import { initProgramSessionTicketRegistration, refreshAvailability } from './program-session-ticket-registration';

export default (() => {
    const presenations = document.querySelectorAll('.program-item--presentation');
    const programEventElements = document.querySelectorAll('.program2-table__column__event');
    const popup = document.getElementById('custom-popup');
    const popupContent = document.getElementById('custom-popup-content');
    const popupClose = document.getElementById('custom-popup-close');
    const backdrop = document.createElement('div');
    backdrop.className = 'custom-backdrop';
    document.body.appendChild(backdrop);

    let interval: any;

    if (typeof popup !== 'undefined' && popup !== null) {

        function closePopup() {
            popup.style.display = 'none';
            backdrop.style.display = 'none';

            clearInterval(interval);
        }

        function showPopup(content: string) {
            popupContent.innerHTML = content;
            popup.style.display = 'block';
            backdrop.style.display = 'block';
        }

        if (typeof popupClose !== 'undefined' && popupClose !== null) {
            popupClose.addEventListener('click', (e) => {
                e.preventDefault();
                closePopup();
            });
        }

        backdrop.addEventListener('click', (e) => {
            closePopup();
        });

        presenations.forEach((presentation) => {
            presentation.addEventListener('click', (e) => {
                e.preventDefault();

                showPopup(presentation.innerHTML);
            });
        });

        programEventElements.forEach((programEventElement: HTMLElement) => {
            programEventElement.addEventListener('click', (e) => {
                e.preventDefault();

                showPopup(programEventElement.dataset.modalContent);

                const availabilityUri = programEventElement.dataset.availabilityUri;

                interval = setInterval(() => {
                    refreshAvailability(popupContent, availabilityUri);
                }, 15000);

                initProgramSessionTicketRegistration(popupContent);

                refreshAvailability(popupContent, availabilityUri);
            });
        });
    }
})();
