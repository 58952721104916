export default (() => {

    const progressBars = document.querySelectorAll(".progress-bar");

    const animateProgressBar = () => {
        progressBars.length &&
        progressBars.forEach((progressBar: HTMLElement) => {
            progressBar.style.width = `${progressBar.dataset.progress}%`;
        });
    };

    animateProgressBar();

    window.addEventListener('scroll', animateProgressBar);

    return true;
})();
