export default (() => {
    document.addEventListener('DOMContentLoaded', function () {
        const ticketCodeInputs = document.querySelectorAll('.js-ticket-code');

        ticketCodeInputs.forEach((input) => {
            input.addEventListener('input', function (event) {
                const target = event.target as HTMLInputElement;

                target.value = target.value.replace(/[^a-zA-Z0-9-]/g, '');
            });
        });
    });
})();
