import 'jquery.countdown';

const $counters = $('.js-countdown');

$counters.each(function () {
    const showSeconds = Boolean($(this).data('show-seconds') == '1');

    $(this).countDown({
        always_show_days: !showSeconds,
        with_labels: true,
        with_seconds: showSeconds,
        with_separators: false,
        with_hh_leading_zero: true,
        with_mm_leading_zero: true,
        with_ss_leading_zero: true,
        label_dd: 'dní',
        label_hh: 'hodin',
        label_mm: 'minut',
        label_ss: 'sekund',
        separator: ':',
        separator_days: ''
    });
});
