import SimpleLightbox from "simplelightbox";

new SimpleLightbox(".gallery-photos a", {
    rel: "gallery",
});

(() => {
    const createVideoLightbox = ({ href }) => {
        const lightbox = document.querySelector(".video-lightbox-wrapper");

        if (lightbox) {
            document.body.removeChild(lightbox);
        }

        return `
            <div class="video-lightbox-wrapper">
                <div class="video-lightbox-overlay">
                    <div class="video-container-wrapper">
                        <div class="video-container">
                            <iframe
                                src="${href}"
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        `;
    };

    if (typeof window !== "undefined") {
        const videos = document.querySelectorAll(".gallery-video");
        if (videos.length > 0) {
            videos.forEach((video) => {
                video.addEventListener("click", (e) => {
                    e.preventDefault();
                    const href = video.getAttribute("href");
                    const lightbox = createVideoLightbox({ href });
                    const placeholder = document.createElement("div");
                    placeholder.innerHTML = lightbox;
                    const node = placeholder.firstElementChild;
                    document.body.appendChild(node);

                    const overlay = document.querySelector(".video-lightbox-overlay");
                    if (overlay) {
                        overlay.addEventListener('click', () => {
                            const lightbox = document.querySelector(".video-lightbox-wrapper");

                            if (lightbox) {
                                document.body.removeChild(lightbox);
                            }
                        })
                    }
                });
            });
        }
    }
})();
