// Source: https://htmldom.dev/drag-to-scroll/

export default (() => {
    document.addEventListener("DOMContentLoaded", () => {
        const programTableElement = document.getElementById("program2-table");
        const programTableElementFullWidth = 1115;

        if (typeof programTableElement === 'undefined' || programTableElement === null) {
            return false;
        }

        let pos = { top: 0, left: 0, x: 0, y: 0 };

        window.addEventListener("resize", () => {
            const programTableElementWidth = programTableElement.clientWidth;

            if (programTableElementWidth < programTableElementFullWidth) {
                programTableElement.style.cursor = "grab";
            } else {
                programTableElement.style.cursor = "default";
            }
        });

        const setCursor = ({ event }: { event: "down" | "up" | null }) => {
            const programTableElementWidth = programTableElement.clientWidth;

            if (programTableElementWidth < programTableElementFullWidth) {
                switch (event) {
                    case null:
                        programTableElement.style.cursor = "grab";
                        break;
                    case "down":
                        programTableElement.style.cursor = "grabbing";
                        programTableElement.style.userSelect = "none";
                        break;
                    default:
                    case "up":
                        programTableElement.style.cursor = "grab";
                        programTableElement.style.removeProperty("user-select");
                        break;
                }
            } else {
                programTableElement.style.cursor = "default";
            }
        };

        const mouseDownHandler = (e: MouseEvent) => {
            setCursor({ event: "down" });

            pos = {
                left: programTableElement.scrollLeft,
                top: programTableElement.scrollTop,
                // Get the current mouse position
                x: e.clientX,
                y: e.clientY,
            };

            document.addEventListener("mousemove", mouseMoveHandler);
            document.addEventListener("mouseup", mouseUpHandler);
        };

        const mouseMoveHandler = (e: MouseEvent) => {
            // How far the mouse has been moved
            const dx = e.clientX - pos.x;
            const dy = e.clientY - pos.y;

            // Scroll the element
            programTableElement.scrollTop = pos.top - dy;
            programTableElement.scrollLeft = pos.left - dx;
        };

        const mouseUpHandler = () => {
            setCursor({ event: "up" });

            document.removeEventListener("mousemove", mouseMoveHandler);
            document.removeEventListener("mouseup", mouseUpHandler);
        };

        // Attach the handler
        programTableElement.addEventListener("mousedown", mouseDownHandler);

        setCursor({ event: null });
    });
})();
