// This is Stimulus controller that handles the login modal for the ticketing system.
// It's a simple controller that load data from the server and replace the modal content with it.
// Modal has id #ticket-login-modal and using bootstrap modal.
// This function has to be triggered when the modal is shown.

import { Controller } from '@hotwired/stimulus';

const ticketLoginModal = document.getElementById('ticket-login-modal');

export default class extends Controller {
    static values = {
        url: String,
    }

    connect() {
        ticketLoginModal.addEventListener('show.bs.modal', () => {
            fetch(this.urlValue)
                .then((response) => response.text())
                .then((data) => {
                    this.element.innerHTML = data;
                });
        });
    }
}

