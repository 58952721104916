const YTPlayer = require("yt-player");
const CHAINCAMP_VIDEO_ID = "o8-RR0IiW7A";

export default (() => {
	const playButton = document.querySelector(".js-hero-play-video");
	const heroCover: HTMLElement = document.querySelector(".js-hero-cover");
	const heroContainer: HTMLElement = document.querySelector(".js-hero-container");

    if (heroContainer !== null) {
        const ytPlayer = new YTPlayer(".js-hero-video", {
       		controls: false,
       		captions: false,
       		annotations: false,
       		related: false,
       		fullscreen: false,
       		width: 1920,
       		height: 1080,
       	});

       	const controlVideoState = () => {
       		const state = ytPlayer.getState();

       		if (state === "playing") {
       			ytPlayer.pause();
       		} else {
       			ytPlayer.play();
       		}
       	};

       	const playVideo = () => {
       		ytPlayer.mute();
       		ytPlayer.play();
       		playButton.remove();
       		heroCover.classList.add("hide");
       		heroContainer.addEventListener("click", controlVideoState);
       	};

       	playButton.addEventListener("click", playVideo);

       	ytPlayer.load(CHAINCAMP_VIDEO_ID);
    }

})();
